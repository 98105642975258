import { CSSProperties, useCallback, VFC } from 'react';

import { embedIframeName } from '../../features/ui/ui.constants';

interface EmbedProjectHtmlProps {
  url: string;
  isBorderless?: boolean;
  testId?: string;
  id?: string;
  onLoad?: () => void;
  onBeforeUnload?: () => void;
}

const EmbedProject: VFC<EmbedProjectHtmlProps> = ({
  isBorderless,
  url,
  testId,
  id,
  onBeforeUnload,
  onLoad,
}) => {
  let style: CSSProperties = {
    position: 'relative',
  };

  if (isBorderless) {
    style = {
      ...style,
      width: '100%',
      height: '100%',
    };
  } else {
    style = {
      ...style,
      paddingBottom: '56.25%',
      width: '100%',
      height: 0,
      transform: 'scale(0.8)',
    };
  }

  const handleIframeRef = useCallback(
    (el: HTMLIFrameElement | null) => {
      if (el) {
        onLoad && el.addEventListener('load', onLoad);
        onBeforeUnload && el.addEventListener('beforeunload', onBeforeUnload);
        el.focus();
      }
    },
    [onBeforeUnload, onLoad]
  );

  return (
    <div style={style} id={id}>
      <iframe
        src={url}
        data-testid={testId}
        allow="fullscreen"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        name={embedIframeName}
        ref={handleIframeRef}
      />
    </div>
  );
};

export default EmbedProject;
